<template>
  <div id="order-view">
    <vs-alert color="danger" title="Order Not Found" :active.sync="order_not_found">
      <span>Order record with id: '{{ $route.params.orderId }}' not found. </span>
      <span>
        <span>Check </span><span @click="$router.push('/orders').catch(() => {})" class="text-inherit underline">All Orders</span>
      </span>
    </vs-alert>

    <div v-if="!isLoading">
      <vs-tabs :value="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
        <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? 'Overview' : ''">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <order-overview class="mb-3"/>
            <order-requirements  class="mb-3"/>
            <order-delivered-files  class="mb-3" />
            <order-review />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-send" :label="!isSmallerScreen ? 'Delivery Unit' : ''">
          <div class="tab-social-links md:ml-4 md:mt-0 mt-4 ml-0">
            <order-delivery-unit />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? 'Messages' : ''">
          <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
            <order-messages />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import OrderOverview from "./OrderOverview.vue"
import OrderRequirements from "./OrderRequirements.vue"
import OrderDeliveryUnit from "./OrderDeliveryUnit.vue"
import OrderDeliveredFiles from "./OrderDeliveredFiles.vue"
import OrderMessages from "./OrderMessages.vue"
import OrderReview from "./OrderReview.vue"

export default {
  components: {
    OrderOverview,
    OrderRequirements,
    OrderDeliveredFiles,
    OrderDeliveryUnit,
    OrderMessages,
    OrderReview
  },
  data() {
    return {
      isLoading: true,
      order_not_found: false,
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeTab(){
      return this.$route.query.tab
        ? +this.$route.query.tab
        : 0;
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  watch: {
    "$route":"fetchOrder"
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$vs.loading({scale: 0.6});
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.isLoading = false
        }else{
          this.order_not_found = true
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error) })
    },
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.con-vs-tabs{
    .con-ul-tabs{
        .vs-icon{
            font-size:1.5rem;
        }
    }
}
</style>
