<template>
  <div>
    <!-- <vs-alert color="warning" title="Order Review" :active.sync="order_not_delivered">
      <span>Expect review after when order is delivered. </span>
    </vs-alert >
    <vs-alert color="warning" title="No Review" :active.sync="isReview">
      <span>The buyer has not made any review </span>
    </vs-alert > -->
    <div v-if="showReview && review">
      <vx-card no-shadow>
        <ul class="list mb-4">
          <li class="list__item px-0">
            <div style="width: 42px;" class="mr-3">
            <vs-avatar class="border-1 ml-0 border-solid border-white" :src="$store.state.domain+'/'+order.buyer.image" size="42px" />
            </div>
            <div class="p-3 flex-grow rounded" style="background-color:#f8f8f8;">
              <h6 class="flex font-semibold"> {{order.buyer.first_name}} {{order.buyer.last_name}} <span class="ml-2 text-warning inline-flex"><feather-icon icon="StarIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>{{review.rating}}</span></h6>
              <p>{{review.content}}</p>
              <div> <span class="opacity-50 text-sm">{{review.created_at | date_ago}}</span>
              </div>
            </div>
          </li>
        </ul>
      </vx-card>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating
  },
  data() {
    return {
      showReview: false,
      order_not_delivered: false,
    }
  },
  computed: {
    validateReply() {
      return this.onReply.reply != ""
    },
    order(){
      return this.$store.state.orderList.order
    },
    review(){
      return this.order.review
    },
    isReview(){
      return this.showReview && !this.review
    }

  },
  created(){
    if((this.order.status == "delivered") || (this.order.status == "completed")){
      this.showReview = true
    }else{
      this.order_not_delivered = true
    }
  }
}
</script>

<style type="text/css" >
  .vue-star-rating-star{
    height: 30px;
    width:30px;
  }
</style>
