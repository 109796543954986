<template>
  <vx-card no-shadow>
    <div class="justify-center m-auto items-center mb-8">
         <!-- Bio -->
      <h6 class=""><span class="feather fa-cloud-upload"></span> Delivery Unit</h6>
      <vs-divider class="my-1"/>
      <vs-upload multiple text="Click to add file" :showUploadButton="false" ref="markdownfiles" class="ml-0"/>
      <div>
        <label>Write a message</label>
        <vs-textarea label="Write something" v-model="message" rows="1" />
      </div>
      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button class="ml-auto mt-2" size="small" @click.prevent="createDelivery()">Deliver order</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>

export default {
  data() {
    return {
      message: "",
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
  },
  methods: {
    createDelivery(){
      const filesx = this.$refs.markdownfiles.filesx.filter((item) => (item.remove !== true))
      this.$validator.validateAll().then(result => {
        if (result && (filesx.length > 0)) {
          let formData = new FormData()
          filesx.forEach((item, index) => formData.append('file_'+index, item))
          formData.append('message', this.message)
          this.$vs.loading({color: "#444", type: "sound"})
          this.$http.post(`/order-deliveries/${this.$route.params.orderId}/create`,formData)
          .then(response => {
            this.$vs.loading.close()
            if(response.data.success){
              this.alertSuccess(response.data.success)
              if(this.order.deliveries.length){
                this.$store.commit("orderList/ADD_DELIVERY", response.data.delivery)
              }else{
                this.$store.dispatch("orderList/fetchOrder",this.order.id)
              }
              this.$refs.markdownfiles.srcs = []
              this.$refs.markdownfiles.filesx = []
              this.message = ""
            }else if(response.data.errors){
              response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
              this.alertError(response.data.error)
            }
          }).catch(err => { this.alertError(err);this.$vs.loading.close();})
        }
      })
    },
  }
}
</script>
