<template>
  <vx-card no-shadow >
    <div>
      <div class="order-details inline-block">
        <h4 class="">Order: #DKO{{order.id}}</h4>
        <div class="text-sm " style="color:#ccc;">
            Buyer: <span style="color: #111;"> {{order.buyer.last_name }} {{order.buyer.first_name }} </span> | {{order.created_at | date(true)}}
         </div>
      </div>
      <div class="price-holder">
        <div id="clockdiv">
          <table class="table m-0">
            <tbody class="text-center">
              <template v-if="timer.status">
                <tr class="head">
                    <td class="">Days</td>
                    <td class="">Hr</td>
                    <td class="">Min</td>
                    <td class="">Sec</td>
                </tr>
                <tr class="timedata">
                    <td> <h4 class="days de">{{ timer.daysSpan }}</h4></td>
                    <td class=""><h4 class="hours de">{{ timer.hoursSpan }}</h4></td>
                    <td class=""><h4 class="minutes de">{{ timer.minutesSpan }}</h4></td>
                    <td class=""><h4 class="seconds"></h4>{{ timer.secondsSpan }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <h4 class="text-danger notify-ex text-center">{{ getOrderMessage }}</h4>
                  <span v-if="order.status == 'completed'" class="text-sm"> {{order.completed_at | date(true) }} </span>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <vs-divider/>
      <div class="flex items-center">
        <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer m-0" left>
              <a class="a-icon " href="#">
                <h3 class="my-0 bold pt-2"><feather-icon icon="AlignJustifyIcon" svgClasses="h-6 w-6" class="mr-2" /></h3>
              </a>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="$router.push(`/orders/${order.id}?tab=2`).catch(()=>{})">
                  <span class="flex items-center">
                    <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Send&nbsp;Message</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <span  class="font-bold h5 my-0">for {{cart.product.name }} </span>
      </div>
      <vs-divider/>
      <div>
        <vs-collapse class="p-0 show-more">
          <vs-collapse-item icon-arrow="label">
            <div slot="header">
              <span class="font-bold">Package Description</span>
            </div>
            <table class="table w-full m-0">
              <tbody class="text-left">
                <template>
                    <th>
                      Features
                    </th>
                    <th  class="px-0">
                      Quantity
                    </th>
                    <th  class="px-0">
                      Duration
                    </th>
                </template>
                <template >
                  <tr >
                    <td >
                      <h6 class="pl-2 py-2"> Package: <span class="text-success">{{orderPackage.name}}</span></h6>
                      <order-feature-list :attributes="attributes" :orderPackage="orderPackage" :addons="addons"/>
                    </td>
                    <td >
                      1
                    </td>
                    <td>
                      {{order.delivery_time | delivery_time}}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    <vs-divider/>
    <vs-list >
      <vs-list-header title="Number of Designers Assigned"></vs-list-header>
        This order was assigned to {{workers.length}} worker{{workers.length>1?'s':''}}
    </vs-list>
    <!-- Save & Reset Button -->
  </vx-card>
</template>
<script>

import OrderFeatureList from "./components/OrderFeatureList.vue"

export default {
  components:{
    OrderFeatureList
  },
  data() {
    return {
      timer: {
        daysSpan: "",
        hoursSpan: "",
        minutesSpan: "",
        secondsSpan: "",
        status: false,
      },
      deadline: "",
      interval: "",
    }
  },
  watch: {
    order(){
      this.timerIgniter()
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.cart.package
    },
    attributes(){
      return this.cart.attributes
    },
    workers(){
        return this.order.workers
    },
    addons(){
      return this.cart.addons
    },
    getOrderMessage() {
      if(this.order.status == 'completed'){
        return 'Order Completed!'
      }else if(this.order.status == 'delivered'){
        return 'Order has been delivered!'
      }else if(!this.order.started_at){
        return 'Order Not Started!'
      }else {
        return 'Time has expired!'
      }
    },
  },
  methods: {
    timerCount(){
        var now = new Date().getTime()
        var distance = this.deadline - now
        if( distance > 0){
            this.calcTime(distance)
        }else{
          this.timer.status = false
          clearInterval(this.interval)
          return
        }
    },
    calcTime(dist){
      // Time calculations for days, hours, minutes and seconds
        this.timer.daysSpan = Math.floor(dist / (1000 * 60 * 60 * 24))
        this.timer.hoursSpan = this.toSlice(Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
        this.timer.minutesSpan = this.toSlice(Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60)))
        this.timer.secondsSpan = this.toSlice(Math.floor((dist % (1000 * 60)) / 1000))
    },
    toSlice(t){
      return ('0' + t).slice(-2)
    },
    timerIgniter(){
      if(this.order.status === "pending" && this.order.is_started){
        let timeToDelivery = new Date(this.order.started_at).getTime() + (parseInt(this.order.delivery_time)*60*60*1000)
        this.deadline = new Date().setTime(timeToDelivery)
        this.timer.status = true
        this.timerCount()
        this.interval = setInterval(() => {
          this.timerCount()
        }, 1000)
      }
    },
  },
  mounted(){
    this.timerIgniter()
  },
  beforeDestroy(){
    clearInterval(this.interval)
  }
}

</script>

<style type="text/css" scoped>

    body,.text-sm{
        font-size: 12px;
    }

    sub{font-size: 12px;}

    #clockdiv{
        display: inline-block;
        float:left;
        margin-left:5px;
    }
    #clockdiv table .timedata td{
      background-color: #f8f8f8;
    }
    #clockdiv table .timedata td h4 {
        margin-bottom: 0px;
        color: #008b9c;
        font-size: 12px;
    }

    #clockdiv .notify-ex{
        font-size: 12px;
    }
    #clockdiv .table th, #clockdiv .table td{
        padding:3px 5px;
    }
    .price-holder{
        float:right;
        display:inline-block;
    }

    @media(max-width: 580px){
      .order-details{
        display: block;
        margin-bottom: 1.5em;

      }
      .price-holder{
        display:block;
        margin-bottom: 1.5em;
      }
    }
    .price-holder:first-child{
        font-size: 34px;
        color:#444;
        display: inline-block;
    }
    table thead tr{
        border-top:2px solid #ededed !important;
        background: #c2c6dc30 !important;
    }

    .table-responsive {
      display: block !important;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

      @media (max-width: 767.98px) {
        .table-responsive-md {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-md > .table-bordered {
          border: 0;
        }
      }

      @media (max-width: 991.98px) {
        .table-responsive-lg {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-lg > .table-bordered {
          border: 0;
        }
      }
    .icon-config{
      width:12px !important;
      height: 12px !important;
    }
</style>

<style>
  .show-more .vs-collapse-item--header{
    padding: 0px;
  }
</style>

